const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: null,
  isInitialState: true,
  user: { username: null, email: null, first_name: null, last_name: null, email:null, id:null,},
  errors: {},
};


export default function auth(state=initialState, action) {
  //console.log(action)
  switch (action.type) {
    case 'USER_LOADING':
      return {...state, isLoading: true, isInitialState: false};
    case 'USER_LOADED':
      return {...state, isAuthenticated: true, isLoading: false, user: action.user, isInitialState: false};
    case 'LOGIN_SUCCESSFUL':
      //console.log(action)
      localStorage.setItem("token", action.data.token);
      let expiry = (Date.now() + (10*60*60*1000));  // 10 hours
      localStorage.setItem("tokenexpires", expiry);
      return {...state, ...action.data, isAuthenticated: true, isLoading: false, errors: null, isInitialState: false};
    case 'REGISTRATION_SUCCESSFUL':
      return {...state, ...action.data, isAuthenticated: false, isLoading: false, errors: null, isInitialState: false};
    case 'AUTHENTICATION_ERROR':
    case 'LOGIN_FAILED':
    case 'REGISTRATION_FAILED':
    case 'LOGOUT_SUCCESSFUL':
      localStorage.removeItem("token");
      return {...state, errors: action.data, token: null, user: {username: null, email: null, first_name: null, last_name: null, email: null},
        isAuthenticated: false, isLoading: false, showLogin: true, isInitialState: false};
    default:
      return state;
  }
}

