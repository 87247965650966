import React from 'react';


class FeedSourceLine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            emailChecked: this.props.email_selected,
            mapChecked: this.props.map_selected,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.email_selected !== prevProps.email_selected) {
            this.setState({ emailChecked: this.props.email_selected })
        }
        if (this.props.map_selected !== prevProps.map_selected) {
            this.setState({ mapChecked: this.props.map_selected })
        }
      } 
      
    shouldComponentUpdate(nextProps) {
        return true;
    }

    handleEmailClicked = () => {
        this.props.clickEmailSelected(!this.state.emailChecked, this.props.pk)
        this.setState({ emailChecked: !this.state.emailChecked });
    }

    handleMapClicked = () => {
        this.props.clickMapSelected(!this.state.mapChecked, this.props.pk)
        this.setState({ mapChecked: !this.state.mapChecked });
    }

    render() {
        return (
            <tr>
                {this.props.userProfile !== null && 
                <td className='horCenter'>
                    <input
                        id={"fs" + this.props.pk}
                        type="checkbox"
                        checked={this.state.emailChecked}
                        onChange={this.handleEmailClicked}
                    />
                    <label htmlFor={"fs" + this.props.pk}>
                    </label>
                </td>
    }
                <td className='horCenter'>
                    <input
                        id={"fsm" + this.props.pk}
                        type="checkbox"
                        checked={this.state.mapChecked}
                        onChange={this.handleMapClicked}
                    />
                    <label htmlFor={"fsm" + this.props.pk}>
                        <span></span>
                    </label>
                </td>
                <td data-rh={this.props.descr}>{this.props.name}</td>
                <td >
                    {this.props.map_marker !== null &&
                        <img src={this.props.map_marker} alt=''></img>
                    }
                </td>
            </tr>
        )
    }
};


export default FeedSourceLine;
