import React from 'react';
import { connect } from 'react-redux';
import * as auth from "../actions/auth";
import ForgotUserId from "../components/ForgotUserId";
import ResetPassword from "../components/ResetPassword";


const customStyles = {
  content: {
    top: '10%',
    left: '10%',
    width: '28.125rem',
    right: 'auto',
    bottom: 'auto',
    height: 'auto',
    transform: 'translate(-5%, -5%)',
    zIndex: '99999',
    fontSize: '1rem',
    showForgotUserId: false,
    resetPassword: false
  }
};

const fieldStyle = {
  width: '18.75rem',
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      username: '',
      displayMsg: '',
      showForm: true,
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    if (/Android [4-6]/.test(navigator.appVersion)) {
      window.addEventListener("resize", function () {
        if (document.activeElement.tagName == "INPUT" || document.activeElement.tagName == "TEXTAREA") {
          window.setTimeout(function () {
            document.activeElement.scrollIntoViewIfNeeded();
          }, 0);
        }
      })
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({ showForm: true, displayMsg: '' })
    this.props.setShowLogin(false);
  }

  onSubmit = e => {
    e.preventDefault();
    if (this.state.username.indexOf('@') > -1) {
      auth.getIdFromEmail(this.state.username)
        .then(res => {
          console.log(res, this.state.username)
          let useThisUsername = this.state.username;
          if (res.data.status === "ok")
            if (res.data.username === null)
              this.setState({ displayMsg: "Unable to log in with these credentials." })
            else {
              useThisUsername = res.data.username[0];
              this.props.login(useThisUsername, this.state.password);
              this.setState({ displayMsg: '' });
            }
        });
    }
    else {
      this.props.login(this.state.username, this.state.password);
    }
  }

  sendResetLink = () => {
    if (this.state.username === '')
      this.setState({ displayMsg: "Complete the User Id item to reset password." })
    else {
      auth.sendResetLink(this.state.username)
        .then(res => {
          this.setState({ displayMsg: "If the User Id or email exists, a Reset Password link was sent to the email on file" });
          if (res.status === "OK")
            this.setState({ showForm: false });
        });
    }
  }

  validEmail = (email) => {
    console.log(email)
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return (true)
    }
    return (false)
  }

  register = () => {
    this.props.setShowRegister(true);
    this.props.setShowLogin(false);
  }

  showForgotUserId = () => {
    this.setState({ showForgotUserId: true })
  }

  closeForgotUserId = () => {
    this.setState({ showForgotUserId: false })
  }

  showResetPassword = () => {
    this.setState({ showResetPassword: true })
  }

  closeResetPassword = () => {
    this.setState({ showResetPassword: false })
  }

  render() {
    if (this.props.isAuthenticated || !this.props.showLogin) {
      return null;
    }

    return (
      <div className="sidebarPopup sidebarCallout settings">

        <div className='panelHeading'><i className='fas fa-user'></i>&nbsp;Login</div>
        <form onSubmit={this.onSubmit} className={this.state.showForm === true ? '' : 'hidden'}
          style={{ "padding": "0.5rem" }}
        >
          {this.props.errors.length > 0 && (
            <ul style={{ "color": "red" }}>
              {this.props.errors.map(error => (
                <li key={error.field}>{error.message}</li>
              ))}
            </ul>
          )}
          <div className="spaceFormElement">
            User ID or Email
            <div style={{ "background": "black", "color": "white" }}>
              <input
                type="text" id="username" tabIndex="1" autoFocus
                autoComplete="username"
                style={fieldStyle}
                onChange={e => this.setState({ username: e.target.value })} />
            </div>
          </div>
          <div className="spaceFormElement">
            Password<br />
            <input
              type="password" id="password" tabIndex="2"
              autoComplete="current-password"
              onChange={e => this.setState({ password: e.target.value })} />
          </div>
          <div className="spaceFormElement">
            By proceeding, you acknowledge you’ve read and agree with &nbsp;
            <a href="https://skytruth.org/privacy-policy/" target="_blank">SkyTruth's Privacy Policy</a> and
            &nbsp;<a href="https://skytruth.org/terms-of-service//" target="_blank">Terms of Service</a>.
          </div>
          <div className="spaceFormElement" style={{ "width": "100%" }}>
            <div style={{ "textAlign": "center" }}>
              <button type="submit">SUBMIT</button>
            </div>
          </div>
        </form>
        <div className="spaceFormElement" style={{ "width": "100%" }}>
          <div style={{ "textAlign": "center" }}>
            <a onClick={this.showForgotUserId} className="otherButton">Forgot User ID</a>
            &nbsp;&nbsp;&nbsp;
            <a onClick={this.showResetPassword} className="otherButton">Reset Password</a>
          </div>
        </div>
        {
          this.state.displayMsg > '' &&
          <div className="displayMsg">{this.state.displayMsg}</div>
        }
        <div style={{ "paddingLeft": "0.5rem" }} className="spaceFormElement">
          Don't have an account? <a onClick={this.register} className="otherButton">Register here.</a>
        </div>

        {this.state.showForgotUserId &&
          <ForgotUserId
            closeForgotUserId={this.closeForgotUserId}
          ></ForgotUserId>
        }
        {this.state.showResetPassword &&
          <ResetPassword
            closeResetPassword={this.closeResetPassword}
          ></ResetPassword>
        }
      </div >

    )
  }
}

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return { field, message: state.auth.errors[field] };
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => {
      return dispatch(auth.login(username, password));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
